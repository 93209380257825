<template>
  <page-content title="产品配件管理">

    <a-descriptions title="产品信息" bordered size="small" :column="{sm:2}">
      <a-descriptions-item label="名称">{{product.name}}</a-descriptions-item>
      <a-descriptions-item label="品牌">{{product.brandName}}</a-descriptions-item>
      <a-descriptions-item label="编码">{{product.sn}}</a-descriptions-item>
      <a-descriptions-item label="型号">{{product.model}}</a-descriptions-item>
      <a-descriptions-item label="件号">{{product.partNo}}</a-descriptions-item>
      <a-descriptions-item label="奖励">{{product.priceReward/100}}元</a-descriptions-item>

    </a-descriptions>

    <common-table
        ref="table"
        path="web/machine/item"
        :columns="columns"
        :query-params="queryParams"
    >

      <template slot="search">
        <a-button ghost type="primary" @click="add">生成二维码</a-button>
        <a-button @click="clickExport"><a-icon type="export"/>导出Excel</a-button>
      </template>

      <template slot="operation" slot-scope="{record}">
<!--        <action-edit @click="edit(record)"></action-edit>-->
        <action-delete @click="remove(record)"></action-delete>
      </template>

    </common-table>

    <ItemAdd ref="itemAdd" @ok="didItemAdd"></ItemAdd>
  </page-content>
</template>

<script>

import ItemAdd from './ItemAdd'

export default {
  name: 'Product',
  components: {
    ItemAdd
  },
  data() {
    return {
      queryParams: {
        productId: this.$route.params.productId
      },
      product:{}
    }
  },
  created() {
    let productId = this.$route.params.productId
    this.$get(`web/machine/product/${productId}`).then(suc=>{
      this.product = suc.data
    })
  },
  computed: {
    columns() {
      return [
        {
          title: '序列号',
          dataIndex: 'serialNumber'
        },
        {
          title: '创建时间',
          dataIndex: 'createdAt',
        },
        {
          title: '安装时间',
          dataIndex: 'installTime',
        },

        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    add() {
      this.$refs.itemAdd.open()
    },
    edit(record) {
      this.$refs.productEdit.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/machine/item/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
    },
    didItemAdd(count) {
      let data = {
        productId: this.queryParams.productId,
        count: count
      }
      this.$post("web/machine/item", data).then(suc => {
        this.getList()
      })
    },
    clickExport() {
      this.$refs.table.export(this.product.name + "_二维码")
    }
  }
}
</script>

<style scoped>

</style>
